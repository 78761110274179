import { onIdentify } from './onIdentify';

import { ACTIONS } from '../../constants/paymentMethodsHub';

import { PaymentMethodsHubComposable } from '../PaymentMethodsHubComposable';

import { providerUtils } from '../../paymentMethodsProviders/PaymentProviderComponents';

jest.mock('../PaymentMethodsHubComposable', () => ({
  PaymentMethodsHubComposable: jest.fn().mockResolvedValue({}),
}));

PaymentMethodsHubComposable.mockImplementation(() => ({
  getProfileInfo: jest.fn(),
  filterResponse: jest.fn(),
}));

jest.mock('../../paymentMethodsProviders/PaymentProviderComponents', () => ({
  providerUtils: jest.fn().mockResolvedValue({}),
}));

const createFromActionMock = jest.fn();
const onAdditionalDetailsMock = jest.fn();

providerUtils.mockImplementation(() => ({
  createFromAction: createFromActionMock,
  onAdditionalDetails: onAdditionalDetailsMock,
}));

const response = {
  action: '{"type": "threeDS2Fingerprint"}',
  providerStatusRaw: 'IdentifyShopper',
};

const composablePayload = {
  contextData: {
    isRedirected: false,
    flowTracking: {
      currentAction: ACTIONS.add,
      addIsEmbedded: false,
    },
  },
  commonEmit: jest.fn(),
  originalResponse: response,
  parentActionCallback: jest.fn(),
  setupProps: {
    props: {
      provider: 'Adyen',
    },
  },
};

const storeData = {
  userData: {
    uuid: 'uuid',
  },
  profile: {},
  CSOperator: {},
};

const callbackFn = jest.fn();

describe('When the "onIdentify" is called', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  describe('And the response is "identifyShopper" and the action is "threeDS2Fingerprint"', () => {
    it('Then the "onAdditionalDetails" method is called', async () => {
      await onIdentify(composablePayload, storeData, callbackFn);

      expect(onAdditionalDetailsMock).toHaveBeenCalled();
    });
  });
});
